import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Typography from '@mui/material/Typography';
import Seo from '../components/Seo';
import { styled } from '@mui/material/styles';

const ImageContainer = styled('div')(
  ({ theme, containsSelection, bigHeight }) => ({
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    height: containsSelection ? bigHeight : '',
  })
);

const PleinAir = () => {
  return (
    <>
      <Seo title="Paintings En plein air" />
      <Typography variant="h4" align="center" sx={{ fontWeight: 700, marginBottom: '32px' }}>En plein air</Typography>
      <ImageContainer>
        <StaticImage
          src="../images/12.jpg"
          formats={['AUTO', 'JPG']}
          alt="Brandon Khaw"
          width={500}
        />
      </ImageContainer>
    </>
  );
};

export default PleinAir;
